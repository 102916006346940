@font-face {
	font-family: 'M PLUS Rounded 1c';
	src: local('M PLUS Rounded 1c'), url('assets/fonts/MPLUSRounded1c-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'M PLUS Rounded 1c Bold';
	src: local('M PLUS Rounded 1c Bold'), url('assets/fonts/MPLUSRounded1c-Bold.ttf') format('truetype');
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'M PLUS Rounded 1c', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
	display: flex;
	flex-direction: column;
	height: 100%;
}
